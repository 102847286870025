import { Seo } from '@wwtr/components/layout';
import NextLink from 'next/link';
import {
  CategoryNavigator,
  Container,
  Heading,
  Link,
  OrganizationsMap,
  OrganizationsMapSearchBar,
  PopularThemes,
  Section,
} from '@wwtr/components/ui';
import React from 'react';
import { useBreakpoints } from '@wwtr/libs/hooks';
import { useQuery } from '@apollo/client';
import {
  Homepage_Homepage_Entry,
  HomepageQuery,
  Themes_Theme_Entry,
} from '@wwtr/generated';
import HOMEPAGE_QUERY from '@wwtr/gql/queries/homepage.gql';
import {
  getNetworkStatusDetails,
  initializeApolloClient,
} from '@wwtr/libs/apollo/utils';
import THEMES_INDEX_QUERY from '@wwtr/gql/queries/themes-index.gql';
import { GetStaticProps } from 'next';
import HOMEPAGE_ORGANIZATION_MAP_CATEGORIES_QUERY from '@wwtr/gql/queries/homepage-organization-map-categories.gql';
import HOMEPAGE_ORGANIZATION_MAP_LOCATIONS_QUERY from '@wwtr/gql/queries/homepage-organization-map-locations.gql';
import { minutesToSeconds } from 'date-fns';
import { Hero } from '../components/ui/Hero';

const Home = () => {
  const { isDesktop, isWideScreen } = useBreakpoints();

  const { data: homepageData, networkStatus: homepageNetworkStatus } =
    useQuery<HomepageQuery>(HOMEPAGE_QUERY, {
      notifyOnNetworkStatusChange: true,
    });

  const { isLoading: homepageIsLoading, isReady: homepageIsReady } =
    getNetworkStatusDetails(homepageNetworkStatus);

  return (
    <>
      <Seo title={'Welkom'} />
      <Section>
        <Container>
          <Hero
            heading={
              (homepageData?.entry as Homepage_Homepage_Entry)?.heroTitle
            }
            description={
              (homepageData?.entry as Homepage_Homepage_Entry)?.heroDescription
            }
            isLoading={homepageIsLoading}
            isAnimated={true}
            totalOrganizations={homepageData?.totalOrganizations}
            totalServices={homepageData?.totalServices}
          />
        </Container>
      </Section>
      <Section>
        <Container>
          <Heading
            className="mb-16 text-center font-light"
            variant="gray-800"
            level={2}
          >
            Waar bent u naar op zoek?
          </Heading>
          <CategoryNavigator />
        </Container>
      </Section>

      <Section as="nav" className="bg-white">
        <Container>
          <Heading
            level={2}
            variant="gray-800"
            className="mb-8 text-center"
            isLoading={homepageIsLoading}
          >
            Populaire onderwerpen
          </Heading>
          <PopularThemes
            items={
              (homepageData?.entry as Homepage_Homepage_Entry)
                ?.themeSelection as Themes_Theme_Entry[]
            }
            isLoading={homepageIsLoading}
          />
        </Container>
      </Section>
      <section className="bg-powerblue relative">
        <Container>
          <div className="grid gap-4 sm:gap-8 grid-cols-12 text-white text-opacity-40 pt-16 pb-32">
            <div className="col-span-12 sm:col-span-8 md:col-span-6 lg:col-span-5 sm:col-start-3 md:col-start-4">
              <Heading level={2} variant="white" className="mb-8 font-light">
                Organisaties in de buurt
              </Heading>
              <div className="mb-8">
                <OrganizationsMapSearchBar isLoading={false} />
              </div>
              <div>
                <p className="mb-6">
                  Ook uw vermelding op wegwijstwenterand.nl? Staat uw
                  organisatie of aanbod er nog niet (correct) op? U kunt uw
                  aanbod zelf invoeren. Zo maken wij de sociale kaart nog beter
                  en completer.
                </p>
                <NextLink href="contact">
                  <Link href="contact" withArrow customUnderline color="white">
                    Ik wil mijn organisatie wijzigen
                  </Link>
                </NextLink>
              </div>
            </div>
          </div>
        </Container>
        {isDesktop && (
          <div className="absolute right-0 top-0 bg-white h-96 w-1/2 h-full">
            <OrganizationsMap />
          </div>
        )}
      </section>
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApolloClient();

  await apolloClient.query({
    query: HOMEPAGE_QUERY,
  });

  await apolloClient.query({
    query: THEMES_INDEX_QUERY,
  });

  await apolloClient.query({
    query: HOMEPAGE_ORGANIZATION_MAP_CATEGORIES_QUERY,
  });

  await apolloClient.query({
    query: HOMEPAGE_ORGANIZATION_MAP_LOCATIONS_QUERY,
  });

  return {
    props: {
      initialApolloState: apolloClient.cache.extract(),
    },
    revalidate: minutesToSeconds(5),
  };
};

export default Home;
