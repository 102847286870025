import React from 'react';
import animationData from '@wwtr/libs/lottie/connections.json';
import { AnimatedSvg } from '@wwtr/components/ui';

export type HeroAnimationProps = {};

export const HeroAnimation = ({}: HeroAnimationProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };
  return (
    <figure className="block relative h-full w-3/4">
      <AnimatedSvg options={defaultOptions} />
    </figure>
  );
};
