import { Cardinal, Heading, Skeleton } from '@wwtr/components/ui';
import React from 'react';
import { HeroAnimation } from '../HeroAnimation';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';

export type HeroProps = {
  heading: string;
  description: string;
  totalOrganizations: number;
  totalServices: number;
  isLoading: boolean;
  isAnimated: boolean;
};

export const Hero = ({
  heading,
  description,
  totalOrganizations,
  totalServices,
  isLoading,
  isAnimated,
}: HeroProps) => {
  const router = useRouter();

  const searchForm = useFormik({
    initialValues: {
      query: '',
    },
    onSubmit: values => {
      router.push(
        { pathname: '/zoek/organisaties', query: { q: values.query } },
        null,
        {
          shallow: false,
        }
      );
    },
  });

  return (
    <div className="grid gap-8 md:grid-cols-12 md:min-h-70vh">
      <div className="md:col-span-7 lg:col-span-6 flex flex-col justify-center h-full space-y-8">
        <Heading
          variant="gray-800"
          level={1}
          isLoading={isLoading}
          className="font-bold"
        >
          {heading}
        </Heading>
        {isLoading && (
          <div className="space-y-4">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-9/12" />
            <Skeleton className="h-4 w-1/3" />
          </div>
        )}
        {!isLoading && (
          <div>
            <p className="text-gray-700 leading-relaxed lg:text-xl lg:leading-relaxed mb-16">
              {description}
            </p>
            <form onSubmit={searchForm.handleSubmit}>
              <input
                type="search"
                name="query"
                onChange={searchForm.handleChange}
                value={searchForm.values.query}
                placeholder="Zoek in het aanbod van organisaties en hulpaanbod"
                className="w-full bg-white h-14 px-5 pr-10 rounded-full border-2 focus:outline-none"
                onFocus={e => e.currentTarget.select()}
              />
              <button type="submit" hidden>
                Zoek
              </button>
            </form>
          </div>
        )}
        <div className="grid grid-cols-2 gap-8 sm:w-1/2 md:w-1/4 lg:w-1/3">
          <div>
            <Cardinal
              count={totalOrganizations}
              duration={2}
              text="organisaties"
              isLoading={isLoading}
              isAnimated={isAnimated}
            />
          </div>
          <div>
            <Cardinal
              count={totalServices}
              duration={2}
              text="aanbod"
              isLoading={isLoading}
              isAnimated={isAnimated}
            />
          </div>
        </div>
      </div>
      <div className="hidden md:flex md:col-span-5 lg:col-span-6 h-full flex-col justify-center items-center">
        <HeroAnimation />
      </div>
    </div>
  );
};
